<template>
  <div class="pay_container">
    <Count></Count>
    <div class="pay_main">
      <p>
        <span class="red">请在100秒内进行操作！</span>
      </p>
      <div class="shm">
        <img src="../assets/yb.jpg" class="shmi" />
        <el-input style="height: 60px;" ref="scanInput" class="inpsm" v-model="handoverSeq"  @blur="inputblur()"></el-input>
      </div>
      <el-button type="primary" @click.native="cancel">取消</el-button>
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from './footer/index'
import { jsonPost, formPost } from '@/baseAPI'
import Count from './count/index'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      patName: '',
      deptName: '',
      count: '',
      timer: '',
      timer1: '',
      query: {},
      handoverSeq: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.scanInput.focus()
    })
  },
  created() {
    // 获取扫码的值
    this.$nextTick(() => {
      this.$watch(
        'handoverSeq',
        this.debounce(newQuery => {
          if (this.handoverSeq) {
            console.log('扫码获取的证件号', this.handoverSeq)
            this.payScanCode(this.handoverSeq)
          }
        }, 1000)
      )
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    // 微信二维码支付
    async payScanCode(authCode) {
      this.loading = true
      let data = {
        cardNo: authCode,
        cardType: '9'
      }
      Promise.all([this.$api.userInfoInHis(data)]).then(data => {
        if (!data[0]) {
          this.loading = false
          this.$message({
            message: '关联失败',
            type: 'warning'
          })
        } else  {
          this.loading = false
          this.$message({
            message: '关联成功',
            type: 'success'
          })
          const scope = data
            window.localStorage.setItem('name', scope[0].name)
            window.localStorage.setItem('cardNo', scope[0].cardNo)
            window.localStorage.setItem('patCardNo', scope[0].patCardNo)
            window.localStorage.setItem('patCardType', scope[0].patCardType)
            this.$router.push('/home')
        }
      })
    },
    // 初始获取焦点
    keyPress() {
      // nextTik 针对DOM 没有渲染出现Undefined问题
      this.$nextTick(() => {
        this.$refs.scanInput.focus()
      })
    },
    // 失去焦点
    inputblur() {
      let that = this
      // FireFox 和 IE 失去焦点，blur直接执行focus 不会生效，加个延时
      setTimeout(() => {
        that.$refs.scanInput.focus()
      }, 10)
    },
    //防抖处理
    debounce(func, delay) {
      let timer
      return function(...args) {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          func.apply(this, args)
        }, delay)
      }
    },
    countdown() {
      const TIME_COUNT = 100
      this.count = TIME_COUNT
      this.timer1 = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
          }
        } else {
          clearInterval(this.timer1)
          this.timer1 = null
        }
      }, 1000)
    },
    cancel() {
      if (this.name == '预约挂号' || this.name == '当天挂号') {
        this.cancelReg()
      }
      this.$router.push('/home')
    },
    // 取消挂号
    cancelReg() {
      let _this = this
      let params = {
        appointmentId: this.query.appointmentId
      }
      Promise.all([_this.$api.unlockReg(params)]).then(data => {
        this.$message({
          message: '您已取消挂号',
          type: 'warning'
        })
      })
    }
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.timer1)
  }
}
</script>
<style>
.pay_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.pay_main {
  text-align: center;
}

.pay_main .count {
  position: absolute;
  top: 60px;
  right: 40px;
  font-size: 34px;
}

.pay_main p {
  color: #2259a1;
  margin-top: 30px;
  font-size: 50px;
}

.pay_main .red {
  color: red;
  font-size: 48px;
}

.pay_main img {
  /* width: 400px;
  height: 400px; */
}

.pay_main .el-button {
  margin: 40px;
  font-size: 40px;
  width: 220px;
  height: 130px;
}

.pay_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.shm {
  position: relative;
  /* width: 600px;
  height: 400px; */
  margin-bottom: 120px;
}

.shmi {
  width: 50%;
}

/* .shmi{
    position: absolute;
    top: -138.4px;
    right: -124.922px;
    width: 100% !important;
    height:418.75px!important;
    z-index: 999;
} */
.inpsm .el-input__inner {
  color: white;
  z-index: -1;
  border-style: none !important;
}
</style>
